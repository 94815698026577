import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';

import { useApollo } from 'lib/apolloClient';
import 'styles/style.css';

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <meta charSet="utf-8" key="charset" />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,shrink-to-fit=no"
        />
        <title>High Road Brewing Company</title>
        <meta name="description" content="Coming soon!" />
      </Head>
      <Component {...pageProps} />
    </ApolloProvider>
  );
};

export default App;
